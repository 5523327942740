
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { DatePicker, Input, Option, Select, Table, TableColumn, Modal } from "view-design";
	import request from "@/request";
	import Loading from "@/components/loading/Loading.vue";
	import { Route } from "vue-router";
	import { ECharts, EChartsOption, init } from "echarts";
	import moment from "moment";

	const step0 = require("@/assets/dealing.png");

	@Component({
		components: {
			DatePicker,
			Select,
			Option,
			Loading,
			Table,
			Input, Modal
		},
		filters: {
			// toPercent (change:number, total:number):string {
			// 	if (total === 0) {
			// 		return "- 0";
			// 	} else {
			// 		const percentage = change / (total - change) * 100;
			// 		if (percentage > 0) {
			// 			return "↑ " + percentage.toFixed(1) + "%";
			// 		} else {
			// 			return "↓ " + (-percentage.toFixed(1)) + "%";
			// 		}
			// 	}
			// },
			// toBackgroundColor (change:string):string {
			// 	if (/↑/.test(change)) {
			// 		return "background-color:#FF5C5C";
			// 	} else {
			// 		return "background-color:dodgerblue";
			// 	}
			// }
		}
	})
		export default class EnergyEle extends Vue {
            colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
            showContent = false;
            isDrawBack = false;
		// 日期选择
		createTimeRange: any = [moment(new Date()).add(-7, 'd').format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD")];

		// 总回路排名数据
		loopData: any[] = [
			{
				name: "1F总务处空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "12%",
				riseData: "15%"
			},
			{
				name: "1F总控室空调系统电耗",
				riseData2: "20%",
				isRise: 1, // 1表示上升，0表示下降
				riseData: "13%"
			},
			{
				name: "2F高一14空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "50%",
				riseData: "11%"
			},
			{
				name: "1F图书馆室空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "40%",
				riseData: "13%"
			},
			{
				name: " 2F上网区空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "10%",
				riseData: "10%"
			},
			{
				name: "1F101室空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "12%",
				riseData: "13%"
			},
			{
				name: "2F图书馆空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "15%",
				riseData: "12%"
			},
            {
				name: "1F合班教室1空调系统电耗",
				isRise: 1, // 1表示上升，0表示下降
				riseData2: "15%",
				riseData: "12%"
			}
		];

		// 切换用电排行和涨跌幅排名
		isSelected:number = 1; // 1表示用电排行，2表示涨跌幅排名

        initModal () {
            this.$nextTick(() => {
                this.setChart3("modal_chart_dian");
            });
        }
        setChart3 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985"
                        }
                    }
                },
                title: {
                    text: "历史用电",
                    textStyle: {
                        color: "#515a6e",
                        fontWeight: 600,
                        fontSize: 14
                    }
                },
				grid: {
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["7:12", "7:13", "7:14", "7:15", "7:16", "7:17", "7:18"]
				},
				yAxis: {
					type: "value",
                    name: "KW·H",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
				series: [
					{
						// name: "空调系统",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [120, 132, 101, 134, 90, 230, 210],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				]
			};
			chart.setOption(myOption);
		}
		// 改变日期选择
		changeDate () {
			console.log("111");
		}

		setChart4 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                legend: {
                    data: ["动力系统电耗", "插座、照明电耗", "其他电耗", "空调系统电耗"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 0
                },
				grid: {
                    top: "10%",
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985"
                        }
                    }
                },
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["7:12", "7:13", "7:14", "7:15", "7:16", "7:17", "7:18"]
				},
				yAxis: {
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
				series: [
					{
						name: "动力系统电耗",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [100, 120, 150, 160, 80, 200, 200]
					},
                    {
						name: "插座、照明电耗",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [120, 132, 101, 134, 90, 230, 210]
					},
                    {
						name: "其他电耗",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[2] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [50, 80, 60, 65, 60, 120, 100]

					},
                    {
						name: "空调系统电耗",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[3] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [150, 162, 131, 174, 120, 330, 310]

					}
				],
                color: this.colors
			};
			chart.setOption(myOption);
		}

		// 总用电能占比饼状图
		getBieEcharts () {
			const myChart: HTMLElement = document.getElementById("echartBie") as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			// @ts-ignore
			const option: EChartsOption = {
				title: {
					text: "",
					subtext: "",
					left: "center"
				},
				tooltip: {
					trigger: "item"
				},
				legend: {
					show: false,
					orient: "vertical",
					left: "left"
				},
				series: [
					{
						name: "总用电能占比",
						type: "pie",
						radius: ["40%", "70%"],
                        itemStyle: {
                            // borderRadius: 10,
                            borderColor: "#ffffff",
                            borderWidth: 10
                        },
						data: [
							{ value: 1048, name: "动力系统电耗" },
							{ value: 735, name: "空调系统电耗" },
							{ value: 580, name: "插座、照明电耗" },
							{ value: 484, name: "其他电耗" }
						],
						label: {
							show: true,
                            formatter: " {b|{b}} \n {c}KW·h \n {per|{d}%}",
                            rich: {
                                b: {
                                    color: "#4C5058",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    lineHeight: 33
                                },
                                per: {
                                    padding: [3, 4],
                                    borderRadius: 4,
                                    lineHeight: 33,
                                    color: "#4261ED"
                                }
                            }
							// formatter (param) {
                            //     // console.log(param);
							// 	return param.name + "\n" + param.value + "KW·h" + "\n" + param.percent + "%";
							// }
						},
						labelLine: {
							smooth: 1,
							length: 40,
							length2: 30
						},
						emphasis: {
							itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
							}
						}
					}
				]
			};
			option && chart.setOption(option);
		}

		// 切换用电排行和涨跌幅排名
		changeSelected (value: number) {
			this.isSelected = value;
		}

		mounted ():void {
            this.setChart4("echartAll");
			this.getBieEcharts(); // 总用电能占比饼状图
            this.$store.commit("changeDraw", true);
		}

		@Watch("showContent")
		onShowContentChange () {
			this.initModal();
		}
        @Watch("$store.state.shebeiModal")
        changeshebeiModal () {
            this.showContent = true;
        }
		beforeRouteLeave (from:Route, to:Route, next:Function):void {
			next();
		}
	}

